import { createApp, watch } from "vue";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import VueApexCharts from "vue3-apexcharts";
import App from "./App.vue";
import { router } from "./router/router";
import "marked-extensions/styles.css";
import "./index.css";
// import "./markdown.css";
import "./assets/style/global.scss";
import en from "@/locales/en.json";
import fr from "@/locales/fr.json";
import { LOCAL_STORAGE_KEY as LOCALE_LOCAL_STORAGE_KEY } from "@/stores/locale.store";
import { useLocaleStore } from "@/stores/locale.store";
import { setupTestingInterceptor } from "./api/testingInterceptor";

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Compostion API
  globalInjection: true, // you must set `true`
  locale:
    localStorage.getItem(LOCALE_LOCAL_STORAGE_KEY) ??
    navigator.language.slice(0, 2),
  fallbackLocale: "en", // set fallback locale
  messages: { en: en, fr: fr }, // set locale messages
});

const app = createApp(App);

app.use(createPinia());
app.use(i18n);
app.use(router);

// Watch for changes in the locale and update the i18n locale accordingly.
const localeStore = useLocaleStore();
watch(
  () => localeStore.locale,
  (newLocale) => {
    i18n.global.locale.value = newLocale;
  }
);

// The app.use(VueApexCharts) will make <apexchart> component available everywhere.
app.use(VueApexCharts);

// Setup interceptors
setupTestingInterceptor();

app.mount("#app");
