import { defineStore } from "pinia";

export const useTestingStore = defineStore("testing", {
  state: () => ({
    testInfo: null,
    questionnaires: [],
    distributionId: null,
    participantTimepointId: null,
  }),

  actions: {
    setTestInfo(testInfo) {
      this.testInfo = testInfo;
      this.questionnaires = testInfo.questionnaires || [];
      this.distributionId = testInfo.distribution_id;
      this.participantTimepointId = testInfo.participant_timepoint_id;
    },
  },

  getters: {
    isTestMode: (state) => !!state.testInfo,
    getQuestionnaires: (state) => state.questionnaires,
    getDistributionId: (state) => state.distributionId,
    getParticipantTimepointId: (state) => state.participantTimepointId,
  },
});
