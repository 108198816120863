import { ref, watch, computed } from "vue";
import { defineStore } from "pinia";

const LOCAL_STORAGE_KEY = "current_page";
export const useNavStore = defineStore("nav", () => {
  const current = ref(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {
      name: null,
      params: { id: null },
    }
  );
  const history = ref([]);

  watch(current, (newCurrent) => {
    if (newCurrent === null || newCurrent.name === null) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      return;
    }
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newCurrent));
  });

  const reset = (initial = null) => {
    current.value = initial;
    history.value = [];
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  const back = (step = 1) => {
    if (history.value.length <= 0) return null;
    for (let i = 0; i < step; i++) {
      history.value.pop();
    }
    current.value = history.value.pop();
  };

  const previous = () => {
    if (history.value.length <= 0) return null;
    return history.value[history.value.length - 1];
  };

  const push = (newCurrent, goingBack = false) => {
    current.value = newCurrent;
    if (goingBack === true) {
      history.value.pop();
    }
    history.value.push(current.value);
  };

  return { current, back, previous, push, reset };
});
