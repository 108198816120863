<script setup>
import { ref } from "vue";
import { useTestingStore, useNavStore } from "@/stores";
const testingStore = useTestingStore();
const navStore = useNavStore();

const selectRef = ref(null);

const handleQuestionnaireChange = async (e) => {
  navStore.push({
    name: "questionnaire",
    params: { id: e.target.value },
  });
  selectRef.value.selectedIndex = 0;
};
</script>

<template>
  <!-- Show questionnaire labels if in test mode -->
  <select
    ref="selectRef"
    class="text-xs bg-gray-300 text-black py-1 px-2 rounded mr-2 w-36"
    @change="handleQuestionnaireChange"
  >
    <option value="">Questionnaire</option>
    <option
      v-for="questionnaire in testingStore.getQuestionnaires"
      :key="questionnaire.id"
      :value="questionnaire.id"
    >
      {{ questionnaire.label }}
    </option>
  </select>
</template>
