<script setup>
import { useNavStore, useLocaleStore } from "@/stores";
import QuestionnaireSelector from "@/views/devTools/QuestionnaireSelector.vue";
const navStore = useNavStore();
const localeStore = useLocaleStore();
</script>

<template>
  <div
    class="flex justify-between fixed bottom-0 mx-auto left-0 right-0 bg-slate-950 bg-opacity-80 p-3"
  >
    <div class="flex items-center">
      <span class="text-xs text-orange-300 mr-2">Test mode</span>
      <button
        class="text-xs bg-gray-300 text-black py-1 px-2 rounded mr-2"
        @click="
          () => {
            navStore.push({ name: 'welcome' });
          }
        "
      >
        Welcome
      </button>
      <button
        class="text-xs bg-gray-300 text-black py-1 px-2 rounded mr-2"
        @click="
          () => {
            navStore.push({ name: 'criteria' });
          }
        "
      >
        Criteria
      </button>
      <button
        class="text-xs bg-gray-300 text-black py-1 px-2 rounded mr-2"
        @click="
          () => {
            navStore.push({ name: 'consent' });
          }
        "
      >
        Consent
      </button>
      <button
        class="text-xs bg-gray-300 text-black py-1 px-2 rounded mr-2"
        @click="
          () => {
            navStore.push({ name: 'contact_form' });
          }
        "
      >
        Contact
      </button>
      <QuestionnaireSelector />
      <button
        class="text-xs bg-gray-300 text-black py-1 px-2 rounded mr-2"
        @click="
          () => {
            navStore.push({ name: 'conclusion' });
          }
        "
      >
        Conclusion
      </button>
    </div>
    <div class="flex flex-row text-sm">
      <div class="cursor-pointer mr-2" @click="localeStore.switchTo('fr')">
        Fr
      </div>
      <div class="cursor-pointer" @click="localeStore.switchTo('en')">En</div>
    </div>
  </div>
</template>
