import { useTestingStore } from "@/stores";

export function setupTestingInterceptor() {
  // Get the original fetch function
  const originalFetch = window.fetch;

  // Override fetch
  window.fetch = async (...args) => {
    // Call original fetch
    const response = await originalFetch(...args);

    // Clone the response to read it
    const clone = response.clone();

    try {
      const data = await clone.json();
      if (data?._test) {
        const testingStore = useTestingStore();
        testingStore.setTestInfo(data._test);
      }
    } catch (error) {
      // Ignore JSON parsing errors
    }

    return response;
  };
}
